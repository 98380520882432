import React from 'react';

import { Divider } from 'antd';

import { Space } from 'ui-kit';

import './style.scss';

import { ManyArchives } from './components/ManyArchives/ManyArchives';
import { SingleArchive } from './components/SingleArchive/SingleArchive';

export const DownloadAccountsModule = () => {
  return (
    <div className={'download-accounts'}>
      <Space wFull={true} direction={'vertical'}>
        <SingleArchive />
        <Divider />
        <ManyArchives />
      </Space>
    </div>
  );
};
