export const getFormattedNumber = (
  number: number | string | undefined
): string => {
  if (!number) return '';
  const localVar = +number;
  return new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(localVar);
};
