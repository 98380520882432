import React from 'react';

import { useQuery } from '@apollo/client';
import { Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { GET_USER_BLUMS_WITH_ERROR } from 'GQL';

import { getFormattedDate, getFormattedNumber } from 'utils';

import { ErroredDataType } from '../../types';

const columns: TableColumnsType<ErroredDataType> = [
  {
    title: 'Номер',
    dataIndex: 'session.phoneNumber',
    key: 'phoneNumber',
    render: (_, record) => record.session.phoneNumber,
  },
  {
    title: 'username',
    dataIndex: 'session.username',
    key: 'username',
    render: (_, record) => record.session.username,
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, record) => getFormattedNumber(record.balance),
  },
  {
    title: 'Ошибка в',
    render: (_, record) => (
      <>
        {record.error && record.erroredAt ? (
          <Tooltip title={record.error}>
            <Tag color={'error'}>
              {getFormattedDate(new Date(+record.erroredAt))}
            </Tag>
          </Tooltip>
        ) : (
          '-'
        )}
      </>
    ),
  },
];

export const WithErrorTable = () => {
  const { data: blumsWithError, loading } = useQuery(GET_USER_BLUMS_WITH_ERROR);

  return (
    <div>
      <Table<ErroredDataType>
        columns={columns}
        rowKey="id"
        dataSource={blumsWithError?.getUserBlumsWithError}
        scroll={{ y: 600 }}
        pagination={false}
        loading={loading}
        virtual={true}
      />
    </div>
  );
};
