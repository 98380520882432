import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';

// import { REFRESH_TOKEN_MUTATION } from 'GQL';
// import { ACCESS_TOKEN_KEY, FORBIDDEN_ERROR, REFRESH_TOKEN_KEY } from 'consts';
// import {
//   getAccessToken,
//   getRefreshToken,
//   setAccessToken,
//   setRefreshToken,
//   showErrorNotification,
// } from 'utils';
import { getAccessToken } from 'utils';

const httpLink = createHttpLink({
  uri: `https://calm-grub-working.ngrok-free.app/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // TODO сделать норм ошибку от бэка
        // const refreshToken = getRefreshToken();
        // if (err.message === FORBIDDEN_ERROR && refreshToken) {
        //   return new Observable((observer) => {
        //     refreshTokenRequest()
        //       .then((response) => {
        //         if (response?.refreshToken && response.accessToken) {
        //           const {
        //             refreshToken: newRefreshToken,
        //             accessToken: newAccessToken,
        //           } = response;
        //
        //           setRefreshToken(newRefreshToken);
        //           setAccessToken(newAccessToken);
        //
        //           operation.setContext(({ headers = {} }) => ({
        //             headers: {
        //               ...headers,
        //               authorization: `Bearer ${newAccessToken}`,
        //             },
        //           }));
        //           // Retry failed request
        //           return forward(operation).subscribe(observer);
        //         } else {
        //           console.log('error');
        //           observer.error(new Error('Unable to refresh token'));
        //         }
        //       })
        //       .catch((error) => {
        //         console.log('error');
        //         observer.error(error);
        //       });
        //   });
        // }
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
