import { gql } from '__generated__';

export const REGISTRATION = gql(`
  mutation registration($email: String!, $password: String!, $affiliateUuid: String!){
    registration(email: $email, password: $password, affiliateUuid: $affiliateUuid)
  }
`);

export const LOGIN = gql(`
  mutation login($email: String!, $password: String!){
    login(email: $email,password: $password){
      userId
      email
      accessToken
    }
  }
`);

export const DOWNLOAD_MANY_ARCHIVES_WITH_SINGLE_ACC_INSIDE = gql(`
  mutation downloadManyArchivesWithSingleAccInside($inputText: String!){
    downloadManyArchivesWithSingleAccInside(inputText: $inputText){
      successLinks
      failedLinks
    }
  }
`);

export const DOWNLOAD_ARCHIVE_WITH_MANY_ACCS_INSIDE = gql(`
  mutation downloadArchiveWithManyAccsInside($inputText: String!){
    downloadArchiveWithManyAccsInside(inputText: $inputText){
     successLinks
     failedLinks
    }
  }
`);

export const MASS_SAVE_TELEGRAM_SESSIONS = gql(`
  mutation massSaveTelegramSessions{
    massSaveTelegramSessions{
      failedSessions
      successSessions
    }
  }
`);
