import React, { useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Input, Row, Tree } from 'antd';
import {
  DOWNLOAD_ARCHIVE_WITH_MANY_ACCS_INSIDE,
  GET_ERROR_SESSIONS,
  GET_SUCCESS_SESSIONS,
  MASS_SAVE_TELEGRAM_SESSIONS,
} from 'GQL';

import { FailedList, SuccessList } from 'components';
import { Space } from 'ui-kit';
import { showErrorNotification } from 'utils';

import { archiveWithManyAccs, oneLink } from '../../consts';

export const SingleArchive = () => {
  const [textWithSingleLink, setTextWithSingleLink] = useState('');
  const [successSessions, setSuccessSessions] = useState<string[]>([]);
  const [failedSessions, setFailedSessions] = useState<string[]>([]);

  const [
    downloadSingleArchive,
    { data: singleArchiveData, loading: loadingSingle, error },
  ] = useMutation(DOWNLOAD_ARCHIVE_WITH_MANY_ACCS_INSIDE, {
    onError: (error) => showErrorNotification(error.message),
  });

  const [
    massSaveTgSessions,
    { data: massSaveTgSessionsData, loading: loadingMassSaveTgSessions },
  ] = useMutation(MASS_SAVE_TELEGRAM_SESSIONS, {
    refetchQueries: [
      { query: GET_SUCCESS_SESSIONS },
      { query: GET_ERROR_SESSIONS },
    ],
    fetchPolicy: 'no-cache',
    onError: (error) => showErrorNotification(error.message),
    onCompleted: (data) => {
      setFailedSessions(data.massSaveTelegramSessions.failedSessions);
      setSuccessSessions(data.massSaveTelegramSessions.successSessions);
    },
  });

  const handleDownloadSingleArchive = () => {
    setFailedSessions([]);
    setSuccessSessions([]);
    downloadSingleArchive({
      variables: {
        inputText: textWithSingleLink,
      },
    });
  };

  const handleMassSaveTgSessions = () => {
    massSaveTgSessions();
  };

  const handleChangeTextWithSingleLink = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setTextWithSingleLink(value);
  };

  const isSuccessSomeDownload =
    !!singleArchiveData?.downloadArchiveWithManyAccsInside.successLinks.length;

  return (
    <Space wFull={true} direction={'vertical'}>
      <p className={'download-accounts__subtitle'}>
        Загрузка одного архива со множеством аккаунтов
      </p>
      <p>Пример структуры архива</p>
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        treeData={archiveWithManyAccs}
      />
      <Input placeholder={oneLink} onChange={handleChangeTextWithSingleLink} />
      <Button
        type={'primary'}
        loading={loadingSingle}
        onClick={handleDownloadSingleArchive}
      >
        Скачать
      </Button>
      <Row gutter={8}>
        <Col span={12}>
          <FailedList
            list={
              singleArchiveData?.downloadArchiveWithManyAccsInside.failedLinks
            }
            title={
              'Ошибка при скачивании по ссылкам. Проверьте корректность ссылок.'
            }
          />
        </Col>
        <Col span={12}>
          <SuccessList
            list={
              singleArchiveData?.downloadArchiveWithManyAccsInside.successLinks
            }
          />
        </Col>
      </Row>
      {isSuccessSomeDownload && (
        <Button
          type={'primary'}
          loading={loadingMassSaveTgSessions}
          onClick={handleMassSaveTgSessions}
        >
          Загрузить сесии в систему
        </Button>
      )}
      <Row gutter={8}>
        <Col span={12}>
          <FailedList list={failedSessions} title={'Невалидные сессии'} />
        </Col>
        <Col span={12}>
          <SuccessList list={successSessions} />
        </Col>
      </Row>
    </Space>
  );
};
