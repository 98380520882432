import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import { REGISTRATION } from 'GQL';

import { Space } from 'ui-kit';
import { showErrorNotification, showSuccessNotification } from 'utils';

export const RegistrationModule = () => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [affiliateUuid, setAffiliateUuid] = useState('');

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };
  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setConfirmPassword(value);
  };

  const handleChangeAffiliateUuid = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setAffiliateUuid(value);
  };

  const [registration, { loading }] = useMutation(REGISTRATION, {
    onCompleted: () => showSuccessNotification('Успешно'),
    onError: (error: Error) => showErrorNotification(error.message),
  });

  const handleRegistration = () => {
    registration({
      variables: {
        email,
        password,
        affiliateUuid,
      },
    });
  };

  const isDisabled = password !== confirmPassword || !affiliateUuid;

  return (
    <Space direction={'vertical'} wFull={true}>
      <Input placeholder={'Email'} onChange={handleChangeEmail} value={email} />
      <Input.Password
        value={password}
        placeholder={'Password'}
        onChange={handleChangePassword}
        visibilityToggle={{
          visible: isVisiblePassword,
          onVisibleChange: setIsVisiblePassword,
        }}
      />
      <Input.Password
        value={confirmPassword}
        placeholder={'Confirm password'}
        onChange={handleChangeConfirmPassword}
        visibilityToggle={{
          visible: isVisibleConfirm,
          onVisibleChange: setIsVisibleConfirm,
        }}
      />
      <Input
        value={affiliateUuid}
        placeholder={'Ref Id'}
        onChange={handleChangeAffiliateUuid}
      />
      <Button
        type={'primary'}
        disabled={isDisabled}
        onClick={handleRegistration}
        loading={loading}
      >
        Зарегистрироваться
      </Button>
    </Space>
  );
};
