import { notification } from 'antd';

export const showSuccessNotification = (msg = 'Успешно') => {
  notification.success({
    message: msg,
    duration: null,
  });
};

export const showErrorNotification = (description: string) => {
  notification.error({
    message: 'Ошибка',
    description: description,
    duration: null,
  });
};
