import React, { useState } from 'react';

import { Button, Card } from 'antd';
import { AuthModule } from 'modules/Auth';
import { RegistrationModule } from 'modules/Registration';

import './style.scss';

export const AuthPage = () => {
  const [isAuth, setIsAuth] = useState(true);

  const handleToggleIsAuth = () => setIsAuth((prev) => !prev);

  const btnTitle = isAuth
    ? 'Зарегистрировать аккаунт'
    : 'Вернуться к авторизации';

  const cardTitle = isAuth ? 'Авторизация' : 'Регистрация';

  return (
    <div className={'auth-page__layout'}>
      <Card title={cardTitle} className={'auth-page__card'}>
        {isAuth ? <AuthModule /> : <RegistrationModule />}

        <Button className={'auth-page__reg-btn'} onClick={handleToggleIsAuth}>
          {btnTitle}
        </Button>
      </Card>
    </div>
  );
};
