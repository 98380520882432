import { TreeDataNode } from 'antd';

export const archiveWithManyAccs: TreeDataNode[] = [
  {
    title: 'archive.zip',
    key: '0-0',
    children: [
      {
        title: 'acc1',
        key: '0-0-0',
        children: [
          {
            title: 'tdata',
            key: '0-0-0-0',
          },
          {
            title: '...',
            key: '0-0-0-1',
          },
        ],
      },
      {
        title: 'acc2',
        key: '1-0-0',
        children: [
          {
            title: 'tdata',
            key: '1-0-0-0',
          },
          {
            title: '...',
            key: '1-0-0-1',
          },
        ],
      },
      {
        title: 'acc3',
        key: '2-0-0',
        children: [
          {
            title: 'tdata',
            key: '2-0-0-0',
          },
          {
            title: '...',
            key: '2-0-0-1',
          },
        ],
      },
    ],
  },
];

export const archiveWithOneAccVariant1: TreeDataNode[] = [
  {
    title: 'archive-variant1.zip',
    key: '0-0',
    children: [
      {
        title: 'acc1',
        key: '0-0-0',
        children: [
          {
            title: 'tdata',
            key: '0-0-0-0',
          },
          {
            title: '...',
            key: '0-0-0-1',
          },
        ],
      },
    ],
  },
];

export const archiveWithOneAccVariant2: TreeDataNode[] = [
  {
    title: 'archive-variant2.zip',
    key: '0-0',
    children: [
      {
        title: 'tdata',
        key: '0-0-0-0',
      },
      {
        title: '...',
        key: '0-0-0-1',
      },
    ],
  },
];
