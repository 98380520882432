/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation registration($email: String!, $password: String!, $affiliateUuid: String!){\n    registration(email: $email, password: $password, affiliateUuid: $affiliateUuid)\n  }\n": types.RegistrationDocument,
    "\n  mutation login($email: String!, $password: String!){\n    login(email: $email,password: $password){\n      userId\n      email\n      accessToken\n    }\n  }\n": types.LoginDocument,
    "\n  mutation downloadManyArchivesWithSingleAccInside($inputText: String!){\n    downloadManyArchivesWithSingleAccInside(inputText: $inputText){\n      successLinks\n      failedLinks\n    }\n  }\n": types.DownloadManyArchivesWithSingleAccInsideDocument,
    "\n  mutation downloadArchiveWithManyAccsInside($inputText: String!){\n    downloadArchiveWithManyAccsInside(inputText: $inputText){\n     successLinks\n     failedLinks\n    }\n  }\n": types.DownloadArchiveWithManyAccsInsideDocument,
    "\n  mutation massSaveTelegramSessions{\n    massSaveTelegramSessions{\n      failedSessions\n      successSessions\n    }\n  }\n": types.MassSaveTelegramSessionsDocument,
    "\n   query getMe{\n    getMe{\n      referenceUuid\n    }\n  }\n": types.GetMeDocument,
    "\n  query getSuccessSessions{\n    getSuccessSessions{\n      sessions{\n        id\n        phoneNumber\n        username\n        fullName\n        authorizedAt\n        blum{\n          id\n        }\n      }\n    }\n  }\n": types.GetSuccessSessionsDocument,
    "\nquery getErrorSessions {\n  getErrorSessions{\n    sessions{\n      id\n      phoneNumber\n      username\n      fullName\n      error\n      erroredAt\n    }\n  }\n}\n": types.GetErrorSessionsDocument,
    "\n  query getUserBlums{\n    getUserBlums{\n      id\n      balance\n      farmStartedAt\n      error\n      erroredAt\n      playPasses\n      dailyReward\n      session{\n        username\n        phoneNumber\n      }\n    }\n  }\n": types.GetUserBlumsDocument,
    "\n query getUserBlumsNoStartedFarm{\n  getUserBlumsNoStartedFarm{\n    id\n    balance\n    session{\n      phoneNumber\n      username\n    }\n  }\n}\n": types.GetUserBlumsNoStartedFarmDocument,
    "\n  query getUserBlumsWithError{\n    getUserBlumsWithError {\n      id\n      erroredAt\n      balance\n      error\n       session{\n         phoneNumber\n         username\n       }\n    }\n}\n": types.GetUserBlumsWithErrorDocument,
    "\n  mutation blumMassStartFarm{\n    blumMassStartFarm\n  }\n": types.BlumMassStartFarmDocument,
    "\n  mutation blumStartFarmByBlumsId($blumsId: [Int!]!){\n    blumStartFarmByBlumsId(blumsId: $blumsId)\n  }\n": types.BlumStartFarmByBlumsIdDocument,
    "\n  mutation blumStartTasksByBlumId($blumsId: [Int!]!){\n    blumStartTasksByBlumsId(blumsId: $blumsId)\n  }\n": types.BlumStartTasksByBlumIdDocument,
    "\n  query getTotalUserBlumBalance{\n    getTotalUserBlumBalance\n  }\n": types.GetTotalUserBlumBalanceDocument,
    "\n  mutation addSessionsToBlum($sessionsId: [Int!]!){\n    addSessionsToBlum(sessionsId: $sessionsId)\n  }\n": types.AddSessionsToBlumDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation registration($email: String!, $password: String!, $affiliateUuid: String!){\n    registration(email: $email, password: $password, affiliateUuid: $affiliateUuid)\n  }\n"): (typeof documents)["\n  mutation registration($email: String!, $password: String!, $affiliateUuid: String!){\n    registration(email: $email, password: $password, affiliateUuid: $affiliateUuid)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation login($email: String!, $password: String!){\n    login(email: $email,password: $password){\n      userId\n      email\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation login($email: String!, $password: String!){\n    login(email: $email,password: $password){\n      userId\n      email\n      accessToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation downloadManyArchivesWithSingleAccInside($inputText: String!){\n    downloadManyArchivesWithSingleAccInside(inputText: $inputText){\n      successLinks\n      failedLinks\n    }\n  }\n"): (typeof documents)["\n  mutation downloadManyArchivesWithSingleAccInside($inputText: String!){\n    downloadManyArchivesWithSingleAccInside(inputText: $inputText){\n      successLinks\n      failedLinks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation downloadArchiveWithManyAccsInside($inputText: String!){\n    downloadArchiveWithManyAccsInside(inputText: $inputText){\n     successLinks\n     failedLinks\n    }\n  }\n"): (typeof documents)["\n  mutation downloadArchiveWithManyAccsInside($inputText: String!){\n    downloadArchiveWithManyAccsInside(inputText: $inputText){\n     successLinks\n     failedLinks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation massSaveTelegramSessions{\n    massSaveTelegramSessions{\n      failedSessions\n      successSessions\n    }\n  }\n"): (typeof documents)["\n  mutation massSaveTelegramSessions{\n    massSaveTelegramSessions{\n      failedSessions\n      successSessions\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query getMe{\n    getMe{\n      referenceUuid\n    }\n  }\n"): (typeof documents)["\n   query getMe{\n    getMe{\n      referenceUuid\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getSuccessSessions{\n    getSuccessSessions{\n      sessions{\n        id\n        phoneNumber\n        username\n        fullName\n        authorizedAt\n        blum{\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSuccessSessions{\n    getSuccessSessions{\n      sessions{\n        id\n        phoneNumber\n        username\n        fullName\n        authorizedAt\n        blum{\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery getErrorSessions {\n  getErrorSessions{\n    sessions{\n      id\n      phoneNumber\n      username\n      fullName\n      error\n      erroredAt\n    }\n  }\n}\n"): (typeof documents)["\nquery getErrorSessions {\n  getErrorSessions{\n    sessions{\n      id\n      phoneNumber\n      username\n      fullName\n      error\n      erroredAt\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUserBlums{\n    getUserBlums{\n      id\n      balance\n      farmStartedAt\n      error\n      erroredAt\n      playPasses\n      dailyReward\n      session{\n        username\n        phoneNumber\n      }\n    }\n  }\n"): (typeof documents)["\n  query getUserBlums{\n    getUserBlums{\n      id\n      balance\n      farmStartedAt\n      error\n      erroredAt\n      playPasses\n      dailyReward\n      session{\n        username\n        phoneNumber\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query getUserBlumsNoStartedFarm{\n  getUserBlumsNoStartedFarm{\n    id\n    balance\n    session{\n      phoneNumber\n      username\n    }\n  }\n}\n"): (typeof documents)["\n query getUserBlumsNoStartedFarm{\n  getUserBlumsNoStartedFarm{\n    id\n    balance\n    session{\n      phoneNumber\n      username\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUserBlumsWithError{\n    getUserBlumsWithError {\n      id\n      erroredAt\n      balance\n      error\n       session{\n         phoneNumber\n         username\n       }\n    }\n}\n"): (typeof documents)["\n  query getUserBlumsWithError{\n    getUserBlumsWithError {\n      id\n      erroredAt\n      balance\n      error\n       session{\n         phoneNumber\n         username\n       }\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation blumMassStartFarm{\n    blumMassStartFarm\n  }\n"): (typeof documents)["\n  mutation blumMassStartFarm{\n    blumMassStartFarm\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation blumStartFarmByBlumsId($blumsId: [Int!]!){\n    blumStartFarmByBlumsId(blumsId: $blumsId)\n  }\n"): (typeof documents)["\n  mutation blumStartFarmByBlumsId($blumsId: [Int!]!){\n    blumStartFarmByBlumsId(blumsId: $blumsId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation blumStartTasksByBlumId($blumsId: [Int!]!){\n    blumStartTasksByBlumsId(blumsId: $blumsId)\n  }\n"): (typeof documents)["\n  mutation blumStartTasksByBlumId($blumsId: [Int!]!){\n    blumStartTasksByBlumsId(blumsId: $blumsId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getTotalUserBlumBalance{\n    getTotalUserBlumBalance\n  }\n"): (typeof documents)["\n  query getTotalUserBlumBalance{\n    getTotalUserBlumBalance\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addSessionsToBlum($sessionsId: [Int!]!){\n    addSessionsToBlum(sessionsId: $sessionsId)\n  }\n"): (typeof documents)["\n  mutation addSessionsToBlum($sessionsId: [Int!]!){\n    addSessionsToBlum(sessionsId: $sessionsId)\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;