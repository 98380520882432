import React, { FC } from 'react';

import { Alert, Tag } from 'antd';

import { Space } from 'ui-kit';

import './style.scss';

interface ISuccessListProps {
  title?: string;
  list?: string[];
}

export const SuccessList: FC<ISuccessListProps> = ({
  list = [],
  title = 'Успешно',
}) => {
  return (
    <>
      {!!list?.length && (
        <Space
          wFull={true}
          direction={'vertical'}
          size={'small'}
          className={'success-list'}
        >
          <Alert message={title} type={'success'} />
          {list.map((link) => (
            <Tag key={Math.random()} color={'green'}>
              {link}
            </Tag>
          ))}
        </Space>
      )}
    </>
  );
};
