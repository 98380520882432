import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Table, TableColumnsType, TableProps, Tag } from 'antd';
import {
  GET_SUCCESS_SESSIONS,
  GET_USER_BLUMS,
  GET_USER_BLUMS_NO_STARTED_FARM,
  GET_USER_BLUMS_WITH_ERROR,
} from 'GQL';

import { TEN_SECONDS } from 'consts';
import { Space } from 'ui-kit';
import { getFormattedDate, showSuccessNotification } from 'utils';

import { ADD_SESSIONS_TO_BLUM } from '../../api';
import { BlumSvg } from '../../icons';
import { ISuccessAccount } from '../../types';

const columns: TableColumnsType<ISuccessAccount> = [
  {
    title: 'Номер телефоа',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Имя пользователя',
    dataIndex: 'username',
  },
  {
    title: 'Полное имя',
    dataIndex: 'fullName',
  },
  {
    title: 'Проверен в ',
    render: (_, record) => (
      <>
        {record.authorizedAt ? (
          <Tag color={'green'}>
            {getFormattedDate(new Date(+record.authorizedAt))}
          </Tag>
        ) : (
          '-'
        )}
      </>
    ),
  },
  {
    title: 'Проекты',
    render: (_, record) => (
      <>
        {record.blum?.id ? (
          <img className={'my-accounts__project-icon'} src={BlumSvg} alt="" />
        ) : (
          ''
        )}
      </>
    ),
  },
];

export const SuccessAccounts = () => {
  const [selectedIdAccounts, setSelectedIdAccounts] = useState<number[]>([]);

  const { data, loading } = useQuery(GET_SUCCESS_SESSIONS, {
    pollInterval: TEN_SECONDS,
  });

  const [addSessionsToBlum, { loading: loadingAddSessionToBlum }] = useMutation(
    ADD_SESSIONS_TO_BLUM,
    {
      onCompleted: (data) => {
        showSuccessNotification(data.addSessionsToBlum);
        setSelectedIdAccounts([]);
      },
      refetchQueries: [
        { query: GET_USER_BLUMS },
        { query: GET_USER_BLUMS_NO_STARTED_FARM },
        { query: GET_USER_BLUMS_WITH_ERROR },
        { query: GET_SUCCESS_SESSIONS },
      ],
      variables: {
        sessionsId: selectedIdAccounts,
      },
    }
  );

  const handleAddSessionsToBlum = () => addSessionsToBlum();

  const rowSelection: TableProps<ISuccessAccount>['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys: selectedIdAccounts,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ISuccessAccount[]
    ) => {
      const ids = selectedRows.map((row) => row.id);
      setSelectedIdAccounts(ids);
    },
  };

  return (
    <Space wFull={true} direction={'vertical'}>
      <Table<ISuccessAccount>
        rowSelection={rowSelection}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data?.getSuccessSessions.sessions}
        scroll={{ y: 600 }}
        pagination={false}
        loading={loading}
        virtual={true}
      />
      <p>Аккаунтов выбрано - {selectedIdAccounts.length}</p>
      <Button
        onClick={handleAddSessionsToBlum}
        disabled={!selectedIdAccounts.length}
        type={'primary'}
        loading={loadingAddSessionToBlum}
      >
        Добавить выбранные сессии в Blum
      </Button>
    </Space>
  );
};
