import { gql } from '__generated__';

export const BLUM_MASS_START_FARM = gql(`
  mutation blumMassStartFarm{
    blumMassStartFarm
  }
`);

export const BLUM_START_FARM_BY_BLUMS_ID = gql(`
  mutation blumStartFarmByBlumsId($blumsId: [Int!]!){
    blumStartFarmByBlumsId(blumsId: $blumsId)
  }
`);

export const BLUM_START_TASKS_BY_BLUMS_ID = gql(`
  mutation blumStartTasksByBlumId($blumsId: [Int!]!){
    blumStartTasksByBlumsId(blumsId: $blumsId)
  }
`);
