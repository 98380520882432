import React, { FC, PropsWithChildren } from 'react';

import { Space as SpaceAnt, SpaceProps } from 'antd';

import './style.scss';

interface ISpaceProps extends SpaceProps, PropsWithChildren {
  wFull?: boolean;
}

export const Space: FC<ISpaceProps> = ({ wFull, children, ...props }) => {
  return (
    <SpaceAnt
      {...props}
      className={'custom-space'}
      style={{ width: wFull ? '100%' : '' }}
    >
      {children}
    </SpaceAnt>
  );
};
