import React, { useState } from 'react';

import { DownOutlined, WarningOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Row, Tag, Tree } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
  DOWNLOAD_MANY_ARCHIVES_WITH_SINGLE_ACC_INSIDE,
  GET_ERROR_SESSIONS,
  GET_SUCCESS_SESSIONS,
  MASS_SAVE_TELEGRAM_SESSIONS,
} from 'GQL';

import { FailedList, SuccessList } from 'components';
import { Space } from 'ui-kit';
import { showErrorNotification } from 'utils';

import {
  archiveWithOneAccVariant1,
  archiveWithOneAccVariant2,
  manyLinks,
} from '../../consts';

export const ManyArchives = () => {
  const [textWithManyLink, setTextWithManyLink] = useState('');
  const [successSessions, setSuccessSessions] = useState<string[]>([]);
  const [failedSessions, setFailedSessions] = useState<string[]>([]);

  const [downloadManyArchive, { data: manyArchiveData, loading: loadingMany }] =
    useMutation(DOWNLOAD_MANY_ARCHIVES_WITH_SINGLE_ACC_INSIDE, {
      onError: (error) => showErrorNotification(error.message),
    });

  const [
    massSaveTgSessions,
    { data: massSaveTgSessionsData, loading: loadingMassSaveTgSessions },
  ] = useMutation(MASS_SAVE_TELEGRAM_SESSIONS, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      { query: GET_SUCCESS_SESSIONS },
      { query: GET_ERROR_SESSIONS },
    ],
    onError: (error) => showErrorNotification(error.message),
    onCompleted: (data) => {
      setFailedSessions(data.massSaveTelegramSessions.failedSessions);
      setSuccessSessions(data.massSaveTelegramSessions.successSessions);
    },
  });

  const handleDownloadManyArchives = () => {
    setFailedSessions([]);
    setSuccessSessions([]);
    downloadManyArchive({
      variables: {
        inputText: textWithManyLink,
      },
    });
  };

  const handleMassSaveTgSessions = () => {
    massSaveTgSessions();
  };

  const handleChangeTextWithManyLinks = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setTextWithManyLink(value);
  };

  const isSuccessSomeDownload =
    !!manyArchiveData?.downloadManyArchivesWithSingleAccInside?.successLinks
      .length;

  return (
    <Space wFull={true} direction={'vertical'}>
      <p className={'download-accounts__subtitle'}>
        Загрузка нескольких архивов с одним аккаунтом
      </p>
      <p>Пример структуры каждого архива</p>
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        treeData={archiveWithOneAccVariant1}
      />
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        treeData={archiveWithOneAccVariant2}
      />
      <Tag icon={<WarningOutlined />} color="red">
        Важно! Ссылки обязательно должны быть разделены пробелом или переносом
        строки
      </Tag>
      <TextArea
        className={'download-accounts__textarea'}
        placeholder={manyLinks}
        onChange={handleChangeTextWithManyLinks}
      />
      <Button
        type={'primary'}
        loading={loadingMany}
        onClick={handleDownloadManyArchives}
      >
        Скачать
      </Button>
      <Row gutter={8}>
        <Col span={12}>
          <FailedList
            list={
              manyArchiveData?.downloadManyArchivesWithSingleAccInside
                .failedLinks
            }
            title={
              'Ошибка при скачивании по ссылкам. Проверьте корректность ссылок.'
            }
          />
        </Col>
        <Col span={12}>
          <SuccessList
            list={
              manyArchiveData?.downloadManyArchivesWithSingleAccInside
                .successLinks
            }
          />
        </Col>
      </Row>
      {isSuccessSomeDownload && (
        <Button
          type={'primary'}
          loading={loadingMassSaveTgSessions}
          onClick={handleMassSaveTgSessions}
        >
          Загрузить сесии в систему
        </Button>
      )}
      <Row gutter={8}>
        <Col span={12}>
          <FailedList list={failedSessions} title={'Невалидные сессии'} />
        </Col>
        <Col span={12}>
          <SuccessList list={successSessions} />
        </Col>
      </Row>
    </Space>
  );
};
