/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type CreateUserDto = {
  affiliateUuid: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegistrationMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  affiliateUuid: Scalars['String']['input'];
}>;


export type RegistrationMutation = { __typename?: 'Mutation', registration: string };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponseDto', userId: number, email: string, accessToken: string } };

export type DownloadManyArchivesWithSingleAccInsideMutationVariables = Exact<{
  inputText: Scalars['String']['input'];
}>;


export type DownloadManyArchivesWithSingleAccInsideMutation = { __typename?: 'Mutation', downloadManyArchivesWithSingleAccInside: { __typename?: 'DownloadArchiveResponseDto', successLinks: Array<string>, failedLinks: Array<string> } };

export type DownloadArchiveWithManyAccsInsideMutationVariables = Exact<{
  inputText: Scalars['String']['input'];
}>;


export type DownloadArchiveWithManyAccsInsideMutation = { __typename?: 'Mutation', downloadArchiveWithManyAccsInside: { __typename?: 'DownloadArchiveResponseDto', successLinks: Array<string>, failedLinks: Array<string> } };

export type MassSaveTelegramSessionsMutationVariables = Exact<{ [key: string]: never; }>;


export type MassSaveTelegramSessionsMutation = { __typename?: 'Mutation', massSaveTelegramSessions: { __typename?: 'MassSaveResponse', failedSessions: Array<string>, successSessions: Array<string> } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe: { __typename?: 'user', referenceUuid: string } };

export type GetSuccessSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuccessSessionsQuery = { __typename?: 'Query', getSuccessSessions: { __typename?: 'GetSessionsResponseDto', sessions: Array<{ __typename?: 'session', id: number, phoneNumber: string, username: string, fullName: string, authorizedAt?: string | null, blum?: { __typename?: 'blum', id: number } | null }> } };

export type GetErrorSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetErrorSessionsQuery = { __typename?: 'Query', getErrorSessions: { __typename?: 'GetSessionsResponseDto', sessions: Array<{ __typename?: 'session', id: number, phoneNumber: string, username: string, fullName: string, error?: string | null, erroredAt?: string | null }> } };

export type GetUserBlumsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserBlumsQuery = { __typename?: 'Query', getUserBlums: Array<{ __typename?: 'UserBlumsResponseDto', id: number, balance: string, farmStartedAt?: string | null, error?: string | null, erroredAt?: string | null, playPasses: number, dailyReward: number, session: { __typename?: 'session', username: string, phoneNumber: string } }> };

export type GetUserBlumsNoStartedFarmQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserBlumsNoStartedFarmQuery = { __typename?: 'Query', getUserBlumsNoStartedFarm: Array<{ __typename?: 'UserBlumsResponseDto', id: number, balance: string, session: { __typename?: 'session', phoneNumber: string, username: string } }> };

export type GetUserBlumsWithErrorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserBlumsWithErrorQuery = { __typename?: 'Query', getUserBlumsWithError: Array<{ __typename?: 'UserBlumsResponseDto', id: number, erroredAt?: string | null, balance: string, error?: string | null, session: { __typename?: 'session', phoneNumber: string, username: string } }> };

export type BlumMassStartFarmMutationVariables = Exact<{ [key: string]: never; }>;


export type BlumMassStartFarmMutation = { __typename?: 'Mutation', blumMassStartFarm: string };

export type BlumStartFarmByBlumsIdMutationVariables = Exact<{
  blumsId: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type BlumStartFarmByBlumsIdMutation = { __typename?: 'Mutation', blumStartFarmByBlumsId: string };

export type BlumStartTasksByBlumIdMutationVariables = Exact<{
  blumsId: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type BlumStartTasksByBlumIdMutation = { __typename?: 'Mutation', blumStartTasksByBlumsId: string };

export type GetTotalUserBlumBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTotalUserBlumBalanceQuery = { __typename?: 'Query', getTotalUserBlumBalance: number };

export type AddSessionsToBlumMutationVariables = Exact<{
  sessionsId: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type AddSessionsToBlumMutation = { __typename?: 'Mutation', addSessionsToBlum: string };


export const RegistrationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"registration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"affiliateUuid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"registration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}},{"kind":"Argument","name":{"kind":"Name","value":"affiliateUuid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"affiliateUuid"}}}]}]}}]} as unknown as DocumentNode<RegistrationMutation, RegistrationMutationVariables>;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"accessToken"}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const DownloadManyArchivesWithSingleAccInsideDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"downloadManyArchivesWithSingleAccInside"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inputText"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"downloadManyArchivesWithSingleAccInside"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inputText"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inputText"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"successLinks"}},{"kind":"Field","name":{"kind":"Name","value":"failedLinks"}}]}}]}}]} as unknown as DocumentNode<DownloadManyArchivesWithSingleAccInsideMutation, DownloadManyArchivesWithSingleAccInsideMutationVariables>;
export const DownloadArchiveWithManyAccsInsideDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"downloadArchiveWithManyAccsInside"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inputText"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"downloadArchiveWithManyAccsInside"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inputText"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inputText"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"successLinks"}},{"kind":"Field","name":{"kind":"Name","value":"failedLinks"}}]}}]}}]} as unknown as DocumentNode<DownloadArchiveWithManyAccsInsideMutation, DownloadArchiveWithManyAccsInsideMutationVariables>;
export const MassSaveTelegramSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"massSaveTelegramSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"massSaveTelegramSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"failedSessions"}},{"kind":"Field","name":{"kind":"Name","value":"successSessions"}}]}}]}}]} as unknown as DocumentNode<MassSaveTelegramSessionsMutation, MassSaveTelegramSessionsMutationVariables>;
export const GetMeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getMe"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMe"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referenceUuid"}}]}}]}}]} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const GetSuccessSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSuccessSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSuccessSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"authorizedAt"}},{"kind":"Field","name":{"kind":"Name","value":"blum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetSuccessSessionsQuery, GetSuccessSessionsQueryVariables>;
export const GetErrorSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getErrorSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getErrorSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"error"}},{"kind":"Field","name":{"kind":"Name","value":"erroredAt"}}]}}]}}]}}]} as unknown as DocumentNode<GetErrorSessionsQuery, GetErrorSessionsQueryVariables>;
export const GetUserBlumsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUserBlums"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserBlums"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"balance"}},{"kind":"Field","name":{"kind":"Name","value":"farmStartedAt"}},{"kind":"Field","name":{"kind":"Name","value":"error"}},{"kind":"Field","name":{"kind":"Name","value":"erroredAt"}},{"kind":"Field","name":{"kind":"Name","value":"playPasses"}},{"kind":"Field","name":{"kind":"Name","value":"dailyReward"}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserBlumsQuery, GetUserBlumsQueryVariables>;
export const GetUserBlumsNoStartedFarmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUserBlumsNoStartedFarm"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserBlumsNoStartedFarm"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"balance"}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserBlumsNoStartedFarmQuery, GetUserBlumsNoStartedFarmQueryVariables>;
export const GetUserBlumsWithErrorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUserBlumsWithError"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserBlumsWithError"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"erroredAt"}},{"kind":"Field","name":{"kind":"Name","value":"balance"}},{"kind":"Field","name":{"kind":"Name","value":"error"}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserBlumsWithErrorQuery, GetUserBlumsWithErrorQueryVariables>;
export const BlumMassStartFarmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"blumMassStartFarm"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blumMassStartFarm"}}]}}]} as unknown as DocumentNode<BlumMassStartFarmMutation, BlumMassStartFarmMutationVariables>;
export const BlumStartFarmByBlumsIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"blumStartFarmByBlumsId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"blumsId"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blumStartFarmByBlumsId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"blumsId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"blumsId"}}}]}]}}]} as unknown as DocumentNode<BlumStartFarmByBlumsIdMutation, BlumStartFarmByBlumsIdMutationVariables>;
export const BlumStartTasksByBlumIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"blumStartTasksByBlumId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"blumsId"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blumStartTasksByBlumsId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"blumsId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"blumsId"}}}]}]}}]} as unknown as DocumentNode<BlumStartTasksByBlumIdMutation, BlumStartTasksByBlumIdMutationVariables>;
export const GetTotalUserBlumBalanceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTotalUserBlumBalance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTotalUserBlumBalance"}}]}}]} as unknown as DocumentNode<GetTotalUserBlumBalanceQuery, GetTotalUserBlumBalanceQueryVariables>;
export const AddSessionsToBlumDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addSessionsToBlum"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sessionsId"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addSessionsToBlum"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sessionsId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sessionsId"}}}]}]}}]} as unknown as DocumentNode<AddSessionsToBlumMutation, AddSessionsToBlumMutationVariables>;