import React from 'react';
import { createHashRouter } from 'react-router-dom';

import { BlumModule } from 'modules/Blum';
import { DownloadAccountsModule } from 'modules/DownloadAccounts';
import { MyAccountsModule } from 'modules/MyAccounts';

import { Routes } from 'consts';
import { ProtectedPage } from 'hoc';
import { AuthPage } from 'pages';

import { MainLayout } from '../layouts';

export const routerConfig = createHashRouter([
  { path: Routes.AUTH, element: <AuthPage /> },
  {
    path: Routes.MAIN,
    element: (
      <ProtectedPage>
        <MainLayout />
      </ProtectedPage>
    ),
    children: [
      {
        index: true,
        path: Routes.MY_ACCOUNTS,
        element: <MyAccountsModule />,
      },
      {
        path: Routes.ADD_ACCOUNTS,
        element: <DownloadAccountsModule />,
      },
      {
        path: Routes.BLUM,
        element: <BlumModule />,
      },
      {
        path: '*',
        element: <MyAccountsModule />,
      },
    ],
  },
]);
