export const manyLinks =
  '+62 TDATA SESSION+JSON. 2FA. Usernames. Indonesia Telegram accounts\n' +
  '\n' +
  'link: https://mega.nz/file/Nm1giaqQb#FnHIjcw0YJ6nH3tf6J0l5OF6EGusoUfx0u4yHwRmJpHY\n' +
  '\n' +
  'link: https://mega.nz/file/hu9RRAwa#HcOUMaNHqjLHBEBwqKqJJPutEKVucUetKW_aIjCofF_8\n' +
  '\n' +
  'link: https://mega.nz/file/wnEU0fbBa#rBA3OuFNAbf3-Xsp_bLgFBCyOIs2zCQI0ePdcb3zvIew';

export const oneLink =
  'https://mega.nz/file/Nm1giaqQb#FnHIjcw0YJ6nH3tf6J0l5OF6EGusoUfx0u4yHwRmJpHY';
