import React, { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router';

import { Routes } from 'consts';
import { getAccessToken } from 'utils';

export const ProtectedPage: FC<PropsWithChildren> = ({ children }) => {
  const isAuth = getAccessToken();

  return isAuth ? <>{children}</> : <Navigate to={Routes.AUTH} />;
};
