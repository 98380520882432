import React, { useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Table, TableColumnsType, TableProps } from 'antd';
import { GET_USER_BLUMS_NO_STARTED_FARM } from 'GQL';

import { TEN_SECONDS } from 'consts';
import { Space } from 'ui-kit';
import {
  getFormattedNumber,
  showErrorNotification,
  showSuccessNotification,
} from 'utils';

import { BLUM_START_FARM_BY_BLUMS_ID } from '../../api';
import { NoStartedDataType } from '../../types';

const columns: TableColumnsType<NoStartedDataType> = [
  {
    title: 'Номер',
    dataIndex: 'session.phoneNumber',
    key: 'phoneNumber',
    render: (_, record) => record.session.phoneNumber,
  },
  {
    title: 'username',
    dataIndex: 'session.username',
    key: 'username',
    render: (_, record) => record.session.username,
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, record) => getFormattedNumber(record.balance),
  },
];

export const NoStartedTable = () => {
  const [selectedBlumsId, setSelectedBlumsId] = useState<number[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const timerIdRef = useRef<any>(null);

  const { data: userBlums, loading } = useQuery(
    GET_USER_BLUMS_NO_STARTED_FARM,
    {
      pollInterval: TEN_SECONDS,
    }
  );

  const [blumStartFarmByBlumsId] = useMutation(BLUM_START_FARM_BY_BLUMS_ID, {
    variables: { blumsId: selectedBlumsId },
    onError: (error) => showErrorNotification(error.message),
    onCompleted: (data) => {
      showSuccessNotification(data.blumStartFarmByBlumsId);
      setSelectedBlumsId([]);
    },
  });

  useEffect(() => {
    // Очищаем таймер при размонтировании
    return () => clearInterval(timerIdRef.current);
  }, []);

  const rowSelection: TableProps<NoStartedDataType>['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys: selectedBlumsId,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: NoStartedDataType[]
    ) => {
      const ids = selectedRows.map((row) => row.id);
      setSelectedBlumsId(ids);
    },
  };

  const handleStartFarmByBlumsId = () => {
    setIsDisabled(true);
    setTimeLeft(60); // Устанавливаем начальное значение таймера в 60 секунд

    // Запускаем таймер обратного отсчета
    timerIdRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 1) {
          clearInterval(timerIdRef.current);
          setIsDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    blumStartFarmByBlumsId();
  };

  return (
    <Space wFull={true} direction={'vertical'}>
      <Table<NoStartedDataType>
        rowSelection={rowSelection}
        columns={columns}
        rowKey="id"
        dataSource={userBlums?.getUserBlumsNoStartedFarm}
        scroll={{ y: 600 }}
        pagination={false}
        loading={loading}
        virtual={true}
      />
      {!!userBlums?.getUserBlumsNoStartedFarm.length && (
        <Button
          disabled={!selectedBlumsId.length || isDisabled}
          onClick={handleStartFarmByBlumsId}
        >
          Начать фарм у выбранных аккаунтов.{' '}
          {timeLeft ? `Подождите -${timeLeft}` : ''}
        </Button>
      )}
    </Space>
  );
};
