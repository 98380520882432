import React from 'react';
import { Outlet } from 'react-router';

import { Col, Row } from 'antd';
import { HeaderModule } from 'modules/Header';
import { Sidebar } from 'modules/Sidebar';

import './style.scss';

export const MainLayout = () => {
  return (
    <div className={'main-layout'}>
      <Row className={'main-layout__header'}>
        <Col span={24}>
          <HeaderModule />
        </Col>
      </Row>
      <Row className={'main-layout__wrapper'}>
        <Col span={4} className={'main-layout__sidebar'}>
          <Sidebar />
        </Col>
        <Col span={20} className={'main-layout__content'}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};
