import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Table,
  TableColumnsType,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import { GET_USER_BLUMS } from 'GQL';

import { TEN_SECONDS } from 'consts';
import { Space } from 'ui-kit';
import {
  getFormattedDate,
  getFormattedNumber,
  showErrorNotification,
  showSuccessNotification,
} from 'utils';

import { BLUM_START_TASKS_BY_BLUMS_ID } from '../../api';
import { DataType } from '../../types';

const columns: TableColumnsType<DataType> = [
  {
    title: 'Номер',
    dataIndex: 'session.phoneNumber',
    key: 'phoneNumber',
    render: (_, record) => record.session.phoneNumber,
  },
  {
    title: 'username',
    dataIndex: 'session.username',
    key: 'username',
    render: (_, record) => record.session.username,
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, record) => getFormattedNumber(record.balance),
  },
  {
    title: 'Сыграно игр',
    dataIndex: 'playPasses',
    key: 'playPasses',
  },
  {
    title: 'Daily strick',
    dataIndex: 'dailyReward',
    key: 'dailyReward',
  },
  {
    title: 'Последний старт фарма',
    dataIndex: 'farmStartedAt',
    key: 'farmStartedAt',
    render: (farmStartedAt: string | null) => (
      <>{farmStartedAt ? getFormattedDate(new Date(+farmStartedAt)) : '-'}</>
    ),
  },
  {
    title: 'Ошибка в',
    render: (_, record) => (
      <>
        {record.error && record.erroredAt ? (
          <Tooltip title={record.error}>
            <Tag color={'error'}>
              {getFormattedDate(new Date(+record.erroredAt))}
            </Tag>
          </Tooltip>
        ) : (
          '-'
        )}
      </>
    ),
  },
];

export const AllRecords = () => {
  const [selectedBlumsId, setSelectedBlumsId] = useState<number[]>([]);

  const { data: userBlums, loading } = useQuery(GET_USER_BLUMS, {
    pollInterval: TEN_SECONDS,
  });

  const [blumStartTasksByBlumsId] = useMutation(BLUM_START_TASKS_BY_BLUMS_ID, {
    variables: { blumsId: selectedBlumsId },
    refetchQueries: [{ query: GET_USER_BLUMS }],
    onError: (error) => showErrorNotification(error.message),
    onCompleted: (data) => {
      showSuccessNotification(data.blumStartTasksByBlumsId);
      setSelectedBlumsId([]);
    },
  });

  const handleStartTasksByBlumsId = () => blumStartTasksByBlumsId();

  const rowSelection: TableProps<DataType>['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys: selectedBlumsId,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      const ids = selectedRows.map((row) => row.id);
      setSelectedBlumsId(ids);
    },
  };

  return (
    <Space wFull={true} direction={'vertical'}>
      <Table<DataType>
        rowSelection={rowSelection}
        columns={columns}
        rowKey="id"
        dataSource={userBlums?.getUserBlums}
        scroll={{ y: 600 }}
        pagination={false}
        loading={loading}
        virtual={true}
      />
      {!!userBlums?.getUserBlums.length && (
        <Button
          type={'primary'}
          disabled={!selectedBlumsId.length}
          onClick={handleStartTasksByBlumsId}
        >
          Запустить выполнение заданий у выбранных аккаунтов
        </Button>
      )}
    </Space>
  );
};
