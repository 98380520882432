import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Tabs, TabsProps, Tag } from 'antd';
import { GET_ERROR_SESSIONS, GET_SUCCESS_SESSIONS } from 'GQL';

import { TEN_SECONDS } from 'consts';

import './style.scss';

import { ErrorAccounts, SuccessAccounts } from './components';

export const MyAccountsModule = () => {
  const [activeTabKey, setActiveTabKey] = useState('1');

  const { data: successAccounts } = useQuery(GET_SUCCESS_SESSIONS, {
    pollInterval: TEN_SECONDS,
  });

  const { data: errorAccounts } = useQuery(GET_ERROR_SESSIONS, {
    pollInterval: TEN_SECONDS,
  });

  const handleSetActiveTabKey = (key: string) => setActiveTabKey(key);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <>
          Рабочие аккаунты -{' '}
          <Tag color={'green'}>
            {successAccounts?.getSuccessSessions.sessions.length}
          </Tag>
        </>
      ),
      children: <SuccessAccounts />,
    },
    {
      key: '2',
      label: (
        <>
          Аккаунты с ошибкой -{' '}
          <Tag color={'red'}>
            {errorAccounts?.getErrorSessions.sessions.length}
          </Tag>
        </>
      ),
      children: <ErrorAccounts />,
    },
  ];

  return (
    <div>
      <Tabs
        items={items}
        activeKey={activeTabKey}
        onChange={handleSetActiveTabKey}
      />
    </div>
  );
};
