import React from 'react';

import type { MenuProps } from 'antd';
import { Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

import { Link, NavLink } from 'react-router-dom';

import { Routes } from 'consts';

import './style.scss';

export const Sidebar = () => {
  const menuItems: MenuItem[] = [
    {
      key: '1',
      label: <NavLink to={Routes.MY_ACCOUNTS}>Мои аккаунты</NavLink>,
    },
    {
      key: '2',
      label: <NavLink to={Routes.ADD_ACCOUNTS}>Добавить аккаунты</NavLink>,
    },
    {
      key: '3',
      label: <NavLink to={Routes.BLUM}>Blum</NavLink>,
    },
  ];

  return (
    <div className={'sidebar'}>
      <Menu selectable={false} items={menuItems} />
    </div>
  );
};
