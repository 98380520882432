import React from 'react';

import { useQuery } from '@apollo/client';
import { Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { GET_ERROR_SESSIONS } from 'GQL';

import { getFormattedDate } from 'utils';

import { IErrorAccount } from '../../types';

const columns: TableColumnsType<IErrorAccount> = [
  {
    title: 'Номер телефоа',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Имя пользователя',
    dataIndex: 'username',
  },
  {
    title: 'Полное имя',
    dataIndex: 'fullName',
  },
  {
    title: 'Ошибка в',
    render: (_, record) => (
      <>
        {record?.erroredAt ? (
          <Tooltip title={record.error}>
            <Tag color={'red'}>
              {getFormattedDate(new Date(+record.erroredAt))}
            </Tag>
          </Tooltip>
        ) : (
          '-'
        )}
      </>
    ),
  },
];

export const ErrorAccounts = () => {
  const { data, loading } = useQuery(GET_ERROR_SESSIONS);

  return (
    <div>
      <Table<IErrorAccount>
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data?.getErrorSessions.sessions}
        scroll={{ y: 600 }}
        pagination={false}
        loading={loading}
        virtual={true}
      />
    </div>
  );
};
