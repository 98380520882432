import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Tabs, TabsProps, Tag } from 'antd';
import {
  GET_USER_BLUMS,
  GET_USER_BLUMS_NO_STARTED_FARM,
  GET_USER_BLUMS_WITH_ERROR,
} from 'GQL';

import { TEN_SECONDS } from 'consts';
import { getFormattedNumber } from 'utils';

import { GET_TOTAL_BLUM_BALANCE } from './api';
import { AllRecords, NoStartedTable, WithErrorTable } from './components';

export const BlumModule = () => {
  const [activeTabKey, setActiveTabKey] = useState('1');

  const { data: userBlums } = useQuery(GET_USER_BLUMS, {
    pollInterval: TEN_SECONDS,
  });

  const { data: noStartedBlums } = useQuery(GET_USER_BLUMS_NO_STARTED_FARM);

  const { data: blumsWithError } = useQuery(GET_USER_BLUMS_WITH_ERROR);

  const { data: totalBalance } = useQuery(GET_TOTAL_BLUM_BALANCE);

  const handleSetActiveTabKey = (key: string) => setActiveTabKey(key);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Все записи - ${userBlums?.getUserBlums.length}`,
      children: <AllRecords />,
    },
    {
      key: '2',
      label: (
        <>
          Фарм не начат -{' '}
          <Tag color={'orange'}>
            {noStartedBlums?.getUserBlumsNoStartedFarm.length}
          </Tag>
        </>
      ),
      children: <NoStartedTable />,
    },
    {
      key: '3',
      label: (
        <>
          Записи с ошибкой -{' '}
          <Tag color={'red'}>
            {blumsWithError?.getUserBlumsWithError.length}
          </Tag>
        </>
      ),
      children: <WithErrorTable />,
    },
  ];

  return (
    <div>
      <Alert
        message={`Итого баланс - ${getFormattedNumber(totalBalance?.getTotalUserBlumBalance)}`}
        type="success"
      />
      <Tabs
        items={items}
        activeKey={activeTabKey}
        onChange={handleSetActiveTabKey}
      />
    </div>
  );
};
