import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import { LOGIN } from 'GQL';

import { Routes } from 'consts';
import { Space } from 'ui-kit';
import { setAccessToken, showErrorNotification } from 'utils';

export const AuthModule = () => {
  const navigate = useNavigate();

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [login, { loading }] = useMutation(LOGIN, {
    onError: (error) => showErrorNotification(error.message),
    onCompleted: (data) => {
      setAccessToken(data.login.accessToken);
      navigate(Routes.MY_ACCOUNTS);
    },
  });

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleLogin = () =>
    login({
      variables: {
        email,
        password,
      },
    });

  return (
    <Space direction={'vertical'} wFull={true}>
      <Input placeholder={'Email'} onChange={handleChangeEmail} />
      <Input.Password
        placeholder={'Password'}
        onChange={handleChangePassword}
        visibilityToggle={{
          visible: isVisiblePassword,
          onVisibleChange: setIsVisiblePassword,
        }}
      />
      <Button loading={loading} type={'primary'} onClick={handleLogin}>
        Войти
      </Button>
    </Space>
  );
};
