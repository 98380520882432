import { gql } from '__generated__';

export const GET_ME = gql(`
   query getMe{
    getMe{
      referenceUuid
    }
  }
`);

export const GET_SUCCESS_SESSIONS = gql(`
  query getSuccessSessions{
    getSuccessSessions{
      sessions{
        id
        phoneNumber
        username
        fullName
        authorizedAt
        blum{
          id
        }
      }
    }
  }
`);

export const GET_ERROR_SESSIONS = gql(`
query getErrorSessions {
  getErrorSessions{
    sessions{
      id
      phoneNumber
      username
      fullName
      error
      erroredAt
    }
  }
}
`);

export const GET_USER_BLUMS = gql(`
  query getUserBlums{
    getUserBlums{
      id
      balance
      farmStartedAt
      error
      erroredAt
      playPasses
      dailyReward
      session{
        username
        phoneNumber
      }
    }
  }
`);

export const GET_USER_BLUMS_NO_STARTED_FARM = gql(`
 query getUserBlumsNoStartedFarm{
  getUserBlumsNoStartedFarm{
    id
    balance
    session{
      phoneNumber
      username
    }
  }
}
`);

export const GET_USER_BLUMS_WITH_ERROR = gql(`
  query getUserBlumsWithError{
    getUserBlumsWithError {
      id
      erroredAt
      balance
      error
       session{
         phoneNumber
         username
       }
    }
}
`);
