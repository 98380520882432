import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_ME } from 'GQL';

export const HeaderModule = () => {
  const { data } = useQuery(GET_ME);

  return <div>Мой реф ID - {data?.getMe.referenceUuid}</div>;
};
